<template>
  <div>
    <v-card v-if="headers.length > 0" flat color="primary" rounded="0" class="mx-0 px-16 mb-16">
      <div class="pt-16"></div>
      <v-card-title class="justify-center  pt-3">
        <h1 class="white--text text-h3 no-break">{{ headers[headersCounter].header }}</h1>
      </v-card-title>
      <v-card-title class="justify-center  pb-1">
        <h2 class="white--text text-h5 no-break">{{ headers[headersCounter].subheader1 }}</h2>
      </v-card-title>
      <v-card-title class="justify-center pt-0">
        <h3 class="white--text text-h5 no-break">{{ headers[headersCounter].subheader2 }}</h3>
      </v-card-title>
      <div class="d-none d-md-block">
      <v-card-text fluid class="px-16 text-center">
        <v-row class="justify-center my-16">
          <v-col v-for="typePost in postTypes" :key="typePost.id" style="cursor: pointer">
            <div>
              <v-avatar color="white" size="100">
                <router-link :to="{ name: 'search', params: { search: typePost.id } }">
                  <div style="
                    box-shadow: inset 0px 0px 0px 6px #1565c0;
                    border-radius: 50%;
                    border: 2px solid white;                  
                    margin: 32px;
                  ">
                    <v-icon color="primary" class="pa-4" :style="{ 'font-size': '60px' }">mdi-{{ typePost.icon }}</v-icon>

                  </div>
                </router-link>
              </v-avatar>
            </div>
            <div class="mt-3">
              <span class="white--text text-h6">{{ typePost.name }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    </v-card>
  </div>
</template>

<script>
import fire from "@/main";

export default {
  data: () => ({
    // loading: false,
    postTypes: [],
    headers: [],
    headersCounter: 0,
  }),
  methods: {
    goto(payload) {
      if (this.path !== payload) this.$router.push(payload);
    },
    async getPostTypes() {
      let viewMessage = this;
      let itemRef = await fire.database().ref('admin/postTypes');
      await itemRef.on('value', async (snapshot) => {
        let items = snapshot.val();
        if (items) {
          let newState = [];
          for (let item in items) {
            if (items[item]) {
              newState.push({
                ...items[item],
                id: item,
                // fieldIds: items[item].fieldIds,
                name: items[item].name,
                postTypeExtended: items[item].postTypeExtended,
              });
            }
          }
          viewMessage.postTypes = newState;
          console.log('postTypes', viewMessage.postTypes)
        }
      });
    },
    async getHeaders() {
      let viewMessage = this;
      let itemRef = await fire.database().ref('admin/crm/mainPageHeaders');
      await itemRef.on('value', async (snapshot) => {
        let items = snapshot.val();
        if (items) {
          let newState = [];
          for (let item in items) {
            if (items[item]) {
              newState.push({
                ...items[item],
                id: item,
              });
            }
          }
          viewMessage.headers = newState;
          console.log('headers', viewMessage.headers)
        }
      });
    },
  },
  created() {
    this.getPostTypes();
    this.getHeaders();
    setInterval(() => {
      this.headersCounter = this.headersCounter + 1;
      if (this.headersCounter >= this.headers.length) {
        this.headersCounter = 0;
      }
    }, 7000);
  },
};
</script>

<style>
.no-break {
  word-break: keep-all;
}
</style>