<template>
  <v-card flat class="mx-auto my-12" max-width="90vw" v-if="user">
    <v-tabs v-model="tab" hide-slider>
      <v-tab
        v-for="item in items"
        :key="item.tab"
        class="text-h5"
        :to="{ name: item.link }"
      >
        <v-icon right v-if="item.icon && item.icon != ''">{{
          item.icon
        }}</v-icon>
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-divider color="#512DA8" class="mb-3"></v-divider>
    <router-view></router-view>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Account",
  data: () => ({
    tab: null,
    dateCreated: new Date(),
    items: [
      
      // {
      //   tab: "מטענים שלך",
      //   icon: "mdi-sack",
      //   link: "MyLuggage",
      // },
      {
        tab: "מודעות שפרסמת",
        icon: "mdi-sack",
        link: "MyLuggage2",
      },
      {
        tab: "הצעות ששלחת",
        icon: "mdi-truck",
        link: "MyTrucking",
      },
      {
        tab: "מודעות מועדפות",
        icon: "mdi-star",
        link: "MyStaredPage",
      },
      {
        tab: "הגדרות",
        icon: "mdi-account",
        link: "MySpace",
      },
      {
        tab: "ביקורות",
        icon: "mdi-message-draw",
        link: "MyReviews",
      },
      // {
      //   tab: "הודעות שלך",
      //   icon: "mdi-message-text",
      //   link: "MyChats",
      // },
    ],
  }),
  methods: {},
  components: {},
  computed: {
    ...mapGetters(["loading", "user"]),
  },
};
</script>

<style scoped>
.v-tab--active {
  border: #1565C0;
  background-color: #1565C0;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  color: white !important;
}
.v-divider {
  border-top-width: 1px;
}
</style>
